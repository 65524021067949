


































































import { Observer } from 'mobx-vue'
import { Component, Vue, Provide, Ref } from 'vue-property-decorator'
import { marketViewModel } from '../viewmodels/market-viewmodel'
import { blockchainHandler } from '@/blockchainHandlers'
import { formatDuration } from '@/modules/ido/business/swap-contract.business'
import moment from 'moment'
import { alertController } from '@/components/alert/alert-controller'

@Observer
@Component({
  components: {
    MarketItem: () => import('../components/market-item.vue'),
    MarketSaleDialog: () => import('../components/market-sale-dialog.vue'),
    ConfirmSaleDialog: () => import('../components/confirm-sale-dialog.vue'),
    MarketBuyDialog: () => import('../components/market-buy-dialog.vue'),
    MarketApproveOfferDialog: () => import('../components/market-approve-offer-dialog.vue'),
    MarketConfirmMakeOfferDialog: () => import('../components/market-confirm-make-offer-dialog.vue'),
    MarketMakeOfferDialog: () => import('../components/market-make-offer-dialog.vue'),
    MarketFilter: () => import('../components/market-filter.vue'),
    MarketStatistical: () => import('../components/market-statistical.vue'),
    MarketHistory: () => import('../components/market-history.vue'),
    Market: () => import('../components/market-filter.vue'),
    SuccessDialog: () => import('../components/success-dialog.vue'),
  },
})
export default class OpenMarket extends Vue {
  @Provide() vm = marketViewModel
  @Ref('marketSaleDialog') marketSaleDialog: any
  @Ref('confirmSaleDialog') confirmSaleDialog: any
  @Ref('marketBuyDialog') marketBuyDialog: any
  @Ref('vestingScheduleDialog') vestingScheduleDialog: any
  @Ref('successDialog') successDialog: any
  @Ref('marketMakeOfferDialog') marketMakeOfferDialog: any
  @Ref('marketConfirmMakeOfferDialog') marketConfirmMakeOfferDialog: any
  @Ref('marketApproveOfferDialog') marketApproveOfferDialog: any

  nftSoldHeaders = [
    { text: '#ID', value: 'id', sortable: false },
    { text: '', value: 'image', sortable: false },
    { text: 'Time', value: 'time', sortable: false },
    { text: 'Buyer', value: 'buyer', sortable: false },
    { text: 'Sale Price', value: 'salePrice', sortable: true },
  ]

  mounted() {
    if (this.vm && !this.vm.loadingPage) {
      this.vm._loadData()
    }
  }
  createdTime(time) {
    return formatDuration(moment.duration(moment().diff(time))) + ' ago'
  }
  chainEnum(chainId) {
    switch (chainId) {
      case 101:
      case 103:
        return 'sol'
      case 1:
      case 3:
        return 'eth'
      case 56:
      case 97:
        return 'bsc'
    }
  }
  openTransactionDetail(item) {
    const { explorer } = blockchainHandler.getChainConfig(item.chainId)
    if (item.transactionHash)
      window.open(`${explorer}tx/${item.transactionHash}${this.getSolanaCluster(item.chainId)}`, '_blank')
  }
  getSolanaCluster(chainId) {
    switch (chainId) {
      case 102:
      case '102':
        return '?cluster=testnet'
      case 103:
      case '103':
        return '?cluster=devnet'
      default:
        return ''
    }
  }
  async openSaleDialog() {
    if (await this.marketSaleDialog.openFromMarket()) {
      await this.confirmSaleDialog.open()
    }
  }
  async openMarketMakeOfferDialog(item) {
    this.vm.changeSelectedItem(item)
    if (await this.marketMakeOfferDialog.open()) {
      await this.marketConfirmMakeOfferDialog.open()
    }
  }
  async openMarketApproveOfferDialog(item) {
    this.vm.changeSelectedItem(item)
    await this.marketApproveOfferDialog.open()
  }
  async openBuyDialog(item) {
    this.vm.changeSelectedItem(item)
    if (await this.marketBuyDialog.open()) {
      await this.successDialog.open(item.keyInfo, item.poolStore)
    }
    this.vm.changeSelectedItem(null)
  }
  async cancelOrder(item) {
    if (
      await alertController.confirm(
        'Cancel selling',
        `Do you want to cancel selling this NFT investment on Forage. NFT will be delisted and you can sell again after.`,
        'Confirm',
        'Cancel'
      )
    ) {
      await this.vm.cancelOrder(item)
    } else {
      return
    }
  }
  async cancelOffer(item) {
    if (
      await alertController.confirm(
        'Cancel offer',
        `Do you want to cancel the offer for this NFT investment. 
And you can still make the other offer for this again.`,
        'Confirm',
        'Cancel'
      )
    ) {
      await this.vm.cancelOffer(item)
    } else {
      return
    }
  }
  async openVestingScheduleDetail() {
    await this.vestingScheduleDialog.open()
  }
}
